import { useEffect, useState } from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadScript } from '@react-google-maps/api';
import { Library } from '@googlemaps/js-api-loader';
import Routing from './routes';
import CssBaseline from '@mui/material/CssBaseline';
import './fonts.css';
import jwt_decode from 'jwt-decode';
import { useLazyGetProfileQuery } from '@Store/api/loginSlice';
import { useDispatch } from 'react-redux';
import { login } from '@Store/slices/auth';
import Loading from '@Components/loading/Loading';
import useIsSomeQueryPending from 'hooks/useIsSomeQueryPending';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
const libraries: Library[] = ['drawing', 'places']

function App() {
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  const isSomeQueryPending = useIsSomeQueryPending();

  const [getProfile] = useLazyGetProfileQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    const checkUserIsAuthenticated = async () => {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        try {
          const userData = jwt_decode(accessToken) as { id: string };
          const user = await getProfile(userData.id).unwrap();
          if (user) dispatch(login(user));
        } catch (err) {
        } finally {
          setTimeout(() => {
            setIsAuthChecked(true);
          }, 200);
        }
      } else {
        setIsAuthChecked(true);
      }
    };

    checkUserIsAuthenticated();
  }, [dispatch, getProfile]);

  return isAuthChecked ? (
    <LoadScript
      googleMapsApiKey={MAPS_API_KEY!}
      id='script-loader'
      language='en'
      libraries={libraries}
      loadingElement={<Loading />}
      region='us'
    >
      {isSomeQueryPending ? <Loading /> : null}
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={RECAPTCHA_SITE_KEY!}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <CssBaseline />
        <ToastContainer />
        <Routing />
      </GoogleReCaptchaProvider>
    </LoadScript>
  ) : (
    <Loading />
  );
}

export default App;
