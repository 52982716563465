import DashboardIcon from '../../assets/icons/dashboard.svg';
import UsersIcon from '../../assets/icons/users.svg';
import ManagementIcon from '../../assets/icons/management.svg';
import ReportingIcon from '../../assets/icons/reporting.svg';
import PoliciesIcon from '../../assets/icons/polices.svg';

import {
  CUSTOMER_REPORT_PAGE,
  DASHBOARD_PAGE,
  FAQ_PAGE,
  NOTIFICATIONS_HISTORY_PAGE,
  NOTIFICATIONS_PAGE,
  PAYMENT_HISTORY_PAGE,
  PRIVACY_POLICIES_PAGE,
  SESSION_PAGE,
  SPACE_TARRIF_MAP_PAGE,
  SPACE_TARRIF_REPORT_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  USERS_PAGE,
  USER_REPORT_PAGE,
  VEHICLE_REPORT_PAGE,
} from '@Routes/constants';

export const navBarItems = [
  {
    icon: DashboardIcon,
    title: 'Dashboard',
    to: DASHBOARD_PAGE,
    subItems: [],
  },
  {
    icon: UsersIcon,
    title: 'Users',
    to: USERS_PAGE,
    subItems: [],
  },
  {
    icon: ManagementIcon,
    title: 'Management',
    to: SPACE_TARRIF_MAP_PAGE,
    subItems: [
      {
        title: 'Space & Tariff Map',
        to: SPACE_TARRIF_MAP_PAGE,
      },
      {
        title: 'Notifications',
        to: NOTIFICATIONS_PAGE,
      },
    ],
  },
  {
    icon: ReportingIcon,
    title: 'Reporting',
    to: USER_REPORT_PAGE,
    subItems: [
      {
        title: 'User Report',
        to: USER_REPORT_PAGE,
      },
      {
        title: 'Customer Report',
        to: CUSTOMER_REPORT_PAGE,
      },
      {
        title: 'Vehicle Report',
        to: VEHICLE_REPORT_PAGE,
      },
      {
        title: 'Space & Tariff Report',
        to: SPACE_TARRIF_REPORT_PAGE,
      },
      {
        title: 'Payment History',
        to: PAYMENT_HISTORY_PAGE,
      },
      {
        title: 'Notifications History',
        to: NOTIFICATIONS_HISTORY_PAGE,
      },
      {
        title: 'Sessions',
        to: SESSION_PAGE,
      },
    ],
  },
  {
    icon: PoliciesIcon,
    title: 'Policies',
    to: FAQ_PAGE,
    subItems: [
      {
        title: 'FAQ',
        to: FAQ_PAGE,
      },
      {
        title: 'Terms & Conditions',
        to: TERMS_AND_CONDITIONS_PAGE,
      },
      {
        title: 'Privacy Policy',
        to: PRIVACY_POLICIES_PAGE,
      },
      {
        title: 'Parking Guide',
        to: '/parking-guide',
      },
    ],
  },
];
