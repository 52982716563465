/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck

import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  GoogleMap,
  Polygon,
  InfoWindow,
  DrawingManager,
} from '@react-google-maps/api';

import { useNavigate, useParams } from 'react-router-dom';

import { Appbar } from '@Components/app-bar';

import { Box, styled } from '@mui/material';
import CustomerFormModal from './CustomerFormModal';

import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';

import resolver, { IPolygonFormData } from './CustomerForm.validation';

import {
  useCreateCustomerMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from '@Store/api/customersSlice';
import { toast } from 'react-toastify';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MapContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - 120px);
  padding: 30px;
  position: relative;
`;

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  borderRadius: 30,
};

const defaultCenter = {
  lat: 51.509865,
  lng: -0.118092,
};

const polygonOptions = {
  fillOpacity: 0.3,
  strokeWeight: 2,
  draggable: true,
  editable: true,
};

export interface IDataToSend extends IPolygonFormData {
  selections: IPolygonData[] & {
    areaMap: string;
  };
}

export const SpaceTarrifMapNewCustomer = () => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowDataIndex, setInfoWindowDataIndex] = useState(-1);

  const params = useParams();

  const navigate = useNavigate();

  const {
    data: customerData,
    error,
    isSuccess,
  } = useGetCustomerQuery(params.id, {
    skip: !params.id,
  });

  const [createCustomer] = useCreateCustomerMutation();

  const [updateCustomer] = useUpdateCustomerMutation();

  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const activePolygonIndex = useRef();
  const drawingManagerRef = useRef();

  const [center, setCenter] = useState(defaultCenter);

  const methods = useForm<IPolygonFormData>({
    defaultValues: {
      selections: [],
    },
    mode: 'all',
    resolver,
  });

  const { control, setValue, watch } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'selections',
  });

  const defaultReminderFee = watch('defaultReminderFee');
  const defaultGraceTime = watch('defaultGraceTime');
  const vatNumber = watch('vatNumber');
  const defaultConvenienceFee = watch('defaultConvenienceFee');

  const watchFieldArray = watch('selections');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    if (isSuccess && customerData) {
      setValue('name', customerData.name);
      setValue('companyEmail', customerData.companyEmail);
      setValue('vatNumber', customerData.vatNumber);
      setValue('defaultReminderFee', customerData.defaultReminderFee);
      setValue('defaultConvenienceFee', customerData.defaultConvenienceFee);
      setValue('merchantId', customerData.merchantId);
      setValue('selections', customerData.selections);
      setValue('defaultGraceTime', customerData.defaultGraceTime);
      setValue('companyAddress', customerData.companyAddress);
      setValue('companyCity', customerData.companyCity);
      setValue('companyRegion', customerData.companyRegion);
      setValue('companyPostCode', customerData.companyPostCode);

      const firstSelection = customerData.selections[0];

      if (firstSelection) {
        if (firstSelection.coordinates[0]) {
          setCenter(firstSelection.coordinates[0]);
        }
      }
    }
  }, [isSuccess, customerData, setValue]);

  useEffect(() => {
    handleFetchError(error);
  }, [error]);

  useEffect(() => {
    if (!vatNumber && controlledFields?.length) {
      controlledFields.forEach((selection, i) => {
        update(i, { ...selection, hasVat: false });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatNumber]);

  const editing = useMemo(() => {
    return !!params.id;
  }, [params]);

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
  };

  const onPolygonClick = (index) => {
    setInfoWindowOpen(true);
    setInfoWindowDataIndex(index);

    document
      .getElementById(`selection-${index + 1}`)
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCloseInfoWindow = () => {
    setInfoWindowOpen(false);
    setInfoWindowDataIndex(-1);
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  const onLoadPolygon = (polygon, index) => {
    polygonRefs.current[index] = polygon;
  };

  const onClickPolygon = (index) => {
    activePolygonIndex.current = index;
  };

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = ($overlayEvent) => {
    drawingManagerRef.current.setDrawingMode(null);

    if ($overlayEvent.type === google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = $overlayEvent.overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      append({
        name: '',
        vatNumber: '',
        merchantId: '',
        coordinates: newPolygon,
        hasVat: false,
        reminderFee: defaultReminderFee,
        graceTime: defaultGraceTime,
        convenienceFee: defaultConvenienceFee,
        defaultTime: 0,
        front_id: Math.random(),
      });

      $overlayEvent.overlay?.setMap(null);
    }
  };

  const onEditPolygon = (index) => {
    const polygonRef = polygonRefs.current[index];

    if (polygonRef) {
      const coordinates = polygonRef
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const allPolygons = [...fields];
      allPolygons[index] = coordinates;

      update(index, {
        ...controlledFields[index],
        coordinates: coordinates,
      });
    }
  };

  const onRemovePolygon = (polygonIndex) => {
    remove(polygonIndex);
  };

  const onSelectionClick = (index: number) => {
    if (fields[index].coordinates[0]) setCenter(fields[index].coordinates[0]);
  };

  const handleVATToggleChange = (index: number, isChecked: boolean) => {
    update(index, {
      ...controlledFields[index],
      hasVat: isChecked,
    });
  };

  const handleFormSubmit: SubmitHandler<IPolygonFormData> = async (data) => {
    const dataToSend: IDataToSend = {
      ...data,
      defaultGraceTime: data.defaultGraceTime || 0,
      selections: data.selections.map(({ _front_id, ...rest }) => ({
        ...rest,
        reminderFee: rest.reminderFee || 0,
        convenienceFee: rest.convenienceFee || 0,
        graceTime: rest.graceTime || 0,
        defaultTime: rest.defaultTime || 0,
        areaMap: JSON.stringify(rest.coordinates), // Convert coordinates to string and assign to areaMap
      })),
    };

    try {
      if (editing) {
        await updateCustomer({ ...dataToSend, id: customerData.id }).unwrap();
        toast.success('Customer details updated successfully');
      } else {
        const customer = await createCustomer(dataToSend).unwrap();
        navigate(`/space-tarrif-map/customer/${customer.id}`);
        toast.success('Customer created successfully');
      }
    } catch (err) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';

      toast.error(message);
    }
  };

  const infoWindowData =
    infoWindowDataIndex > -1 ? controlledFields[infoWindowDataIndex] : null;

  return (
    <Container>
      <Appbar
        subTitle={editing ? undefined : 'Select Area'}
        title={editing ? customerData?.name : 'New Customer'}
      />
      <MapContainer>
        <CustomerFormModal
          controlledFields={controlledFields}
          convenienceFee={defaultConvenienceFee}
          editing={editing}
          fields={fields}
          graceTime={defaultGraceTime}
          handleFormSubmit={handleFormSubmit}
          handleSelectionClick={onSelectionClick}
          methods={methods}
          reminderFee={defaultReminderFee}
          vatNumber={vatNumber}
          onRemovePolygon={onRemovePolygon}
          onVATToggleChange={handleVATToggleChange}
        />
        <GoogleMap
          center={center}
          mapContainerStyle={containerStyle}
          zoom={15}
          onLoad={onLoadMap}
          onTilesLoaded={() => setCenter(null)}
        >
          <DrawingManager
            options={drawingManagerOptions}
            onLoad={onLoadDrawingManager}
            onOverlayComplete={onOverlayComplete}
          />
          {fields.map((field, index) => (
            <Polygon
              draggable
              editable
              key={index}
              options={polygonOptions}
              paths={field.coordinates}
              onClick={() => onPolygonClick(index)}
              onDragEnd={() => onEditPolygon(index)}
              onLoad={(event) => onLoadPolygon(event, index)}
              onMouseDown={() => onClickPolygon(index)}
              onMouseUp={() => onEditPolygon(index)}
            />
          ))}
          {infoWindowOpen && infoWindowData ? (
            <InfoWindow
              position={{
                lat: infoWindowData.coordinates[0].lat,
                lng: infoWindowData.coordinates[0].lng,
              }}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                <p>
                  <b>Subarea name:</b> {infoWindowData.subareaName}
                </p>
                <p>
                  <b>Subarea ID:</b> {infoWindowData.subAreaId}
                </p>
                <p>
                  <b>Config ID:</b> {infoWindowData.configId}
                </p>
                <p>
                  <b>Default Time (minutes):</b> {infoWindowData.defaultTime}
                </p>
                <p>
                  <b>Reminder Fee:</b> {infoWindowData.reminderFee}
                </p>
                <p>
                  <b>Convenience Fee:</b> {infoWindowData.convenienceFee}
                </p>
                <p>
                  <b>Has VAT:</b> {infoWindowData.hasVat ? 'True' : 'False'}
                </p>
              </div>
            </InfoWindow>
          ) : null}
        </GoogleMap>
      </MapContainer>
    </Container>
  );
};
