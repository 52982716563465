import { IUser } from '@Store/slices/auth';
import { apiSlice } from './apiSlice';
import { ITableRequestParams } from '@Types/Table';
import { ICreateOrEditUser } from 'pages/users/create-or-edit-user/CreateOrEditUserForm.validation';
import { IDashboardStatistics } from '@Types/DashboardStatisticsType';
import { IUserRoles } from '@Types/RoleType';
import { IGenericListModel } from '@Types/GenericListModel';
import { IUserReport } from '@Types/UserReport';

interface IUsersGetAllResponse extends IGenericListModel<IUser> {}

const usersApi = apiSlice
  .enhanceEndpoints({ addTagTypes: ['users', 'dashboardUsers', 'roles'] })
  .injectEndpoints({
    endpoints: (build) => ({
      users: build.query<IUsersGetAllResponse, ITableRequestParams>({
        query: ({
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        }) => ({
          url: '/api/Users/GetAll',
          method: 'POST',
          body: {
            sortFields,
            search,
            pageSize,
            currentPage,
            getAllPages,
          },
        }),
        keepUnusedDataFor: 0,
        providesTags: ['users'],
      }),
      createUser: build.mutation<any, ICreateOrEditUser>({
        query: (data) => ({
          url: '/api/Users/Create',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: ['users', 'dashboardUsers'],
      }),
      updateUser: build.mutation<any, ICreateOrEditUser>({
        query: (data) => ({
          url: '/api/Users/Update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['users'],
      }),
      deleteUser: build.mutation<any, string>({
        query: (id) => ({
          url: `/api/Users/Delete/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['users'],
      }),
      dashboardUsers: build.query<IDashboardStatistics, string>({
        query: (url) => ({
          url
        }),
        keepUnusedDataFor: 30,
      }),
      gerUserRoles: build.query<IUserRoles[], void>({
        query: () => ({
          url: '/api/Users/GetRoles',
        }),
        providesTags: ['roles'],
        keepUnusedDataFor: 1200,
      }),
      usersReport: build.query<
        IGenericListModel<IUserReport>,
        ITableRequestParams
      >({
        query: ({
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        }) => ({
          url: '/api/Users/GetUserHistoryAll',
          method: 'POST',
          body: {
            sortFields,
            search,
            pageSize,
            currentPage,
            getAllPages,
          },
        }),
        keepUnusedDataFor: 0,
        providesTags: ['user-report'] as any,
      }),
      usersReportBySelectedId: build.mutation<IUserReport[], string[]>({
        query: (data) => ({
          url: '/api/Users/GetUserHistoryBySelectedId',
          method: 'POST',
          body: data,
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useUsersQuery,
  useDashboardUsersQuery,
  useGerUserRolesQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUsersReportQuery,
  useUsersReportBySelectedIdMutation,
  useLazyUsersReportQuery,
} = usersApi;
