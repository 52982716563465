/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  styled,
  Button,
  Tooltip,
} from '@mui/material';
import FormInput from 'UI/Form/FormInput';
import FormNumberInput from 'UI/Form/FormNumberInput';
import { SwitchButton } from '@UI/SwitchButton';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

const RemoveIcon = () => (
  <svg
    fill='none'
    height='12'
    viewBox='0 0 12 12'
    width='12'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.7472 10.5352C11.9081 10.6962 11.9986 10.9145 11.9986 11.1422C11.9986 11.3698 11.9081 11.5881 11.7472 11.7491C11.5862 11.91 11.3679 12.0005 11.1403 12.0005C10.9126 12.0005 10.6943 11.91 10.5333 11.7491L6 7.21431L1.46523 11.7476C1.30427 11.9086 1.08595 11.999 0.858315 11.999C0.630676 11.999 0.41236 11.9086 0.251395 11.7476C0.0904294 11.5867 3.39209e-09 11.3684 0 11.1407C-3.39209e-09 10.9131 0.0904295 10.6948 0.251395 10.5338L4.78616 6.00047L0.252822 1.4657C0.0918573 1.30474 0.00142808 1.08642 0.00142808 0.858785C0.00142808 0.631145 0.0918573 0.41283 0.252822 0.251864C0.413788 0.0908992 0.632104 0.000469766 0.859743 0.000469763C1.08738 0.000469761 1.3057 0.0908992 1.46666 0.251864L6 4.78663L10.5348 0.25115C10.6957 0.090185 10.914 -0.000244144 11.1417 -0.000244141C11.3693 -0.000244137 11.5876 0.090185 11.7486 0.25115C11.9096 0.412116 12 0.630431 12 0.85807C12 1.08571 11.9096 1.30403 11.7486 1.46499L7.21384 6.00047L11.7472 10.5352Z'
      fill='white'
    />
  </svg>
);

const RemoveButton = styled(Button)(() => ({
  backgroundColor: '#a91d54',
  padding: '5px 10px !important',
  minHeight: 32,
  maxHeight: 32,
  height: 32,
  borderRadius: 10,
  color: '#FFF',

  svg: {
    marginRight: 10,
  },

  textTransform: 'capitalize',

  '&:hover': { backgroundColor: '#a91d54' },
}));

const FieldTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldContainer = styled(Box)`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0px;

  & .MuiFormHelperText-root {
    color: red !important;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  margin-left: 20px !important;
  margin-bottom: 20px;
`;

const SelectionId = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ModalContentContainer = styled(Box)`
  overflow: auto;
  padding: 20px;
  height: calc(100% - 100px);
`;

const VATTitle = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

function isGreaterOrLess(a: number, b: number) {
  return !Number.isNaN(a) && !Number.isNaN(b) && a !== b;
}

function CustomerFormModal({
  methods,
  fields,
  handleFormSubmit,
  onRemovePolygon,
  editing = false,
  handleSelectionClick,
  onVATToggleChange,
  reminderFee,
  graceTime,
  convenienceFee,
  controlledFields,
  vatNumber,
}: any) {
  const handleVATToggleChange = (index: number, isChecked: boolean) => {
    onVATToggleChange(index, isChecked);
  };

  const { setValue } = methods;

  useEffect(() => {
    if (!vatNumber) {
      // If 'vatNumber' is cleared, reset the dependent fields
      setValue('companyAddress', null);
      setValue('companyCity', null);
      setValue('companyRegion', null);
      setValue('companyPostCode', null);
    }
  }, [vatNumber, setValue]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 40,
        right: 112,
        height: 'calc(100vh - 200px)',
        width: 460,
        bgcolor: '#FFF',
        borderRadius: '30px',
        boxShadow: 24,
        paddingTop: 4,
        paddingBottom: 4,
        border: 'none',
        outline: 'none',
        zIndex: 9998,
      }}
    >
      <StyledTypography variant='body2'>Customer Form</StyledTypography>
      <ModalContentContainer>
        <FormProvider {...methods}>
          <FormContainer
            component={'form'}
            id='customer-form'
            onSubmit={(e) => {
              methods.handleSubmit(handleFormSubmit, () => {
                toast.error('One or more fields are not filled correctly');
              })(e);
            }}
          >
            <FormInput label='Customer Name' name={`name`} />
            <div style={{ marginTop: 30 }}>
              <FormInput label='Email' name={`companyEmail`} />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput label='VAT Number' name={`vatNumber`} />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormNumberInput
                decimalScale={4}
                label='Reminder Fee'
                name={`defaultReminderFee`}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormNumberInput
                decimalScale={4}
                label='Convenience Fee'
                name={`defaultConvenienceFee`}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput label='Merchand ID' name={`merchantId`} />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormNumberInput
                decimalScale={4}
                label='Grace Time (minutes)'
                name={'defaultGraceTime'}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput
                disabled={!vatNumber}
                label='Address'
                name={`companyAddress`}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput
                disabled={!vatNumber}
                label='City'
                name={`companyCity`}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput
                disabled={!vatNumber}
                label='Region'
                name={`companyRegion`}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <FormInput
                disabled={!vatNumber}
                label='Post Code'
                name={`companyPostCode`}
              />
            </div>
            {fields.length > 0 && <Divider sx={{ marginTop: '30px' }} />}
            {fields.map((field: any, index: number) => {
              return (
                <FieldContainer id={`selection-${index + 1}`} key={field.id || field.front_id}>
                  <FieldTitleContainer>
                    <SelectionId onClick={() => handleSelectionClick(index)}>
                      {`Selection #${index + 1}`}
                    </SelectionId>
                    <RemoveButton onClick={() => onRemovePolygon(index)}>
                      <RemoveIcon />
                      Remove
                    </RemoveButton>
                  </FieldTitleContainer>
                  <div style={{ marginTop: 30 }}>
                    <FormInput
                      label='Subarea Name'
                      name={`selections.${index}.subareaName`}
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormInput
                      label='Subarea ID'
                      name={`selections.${index}.subAreaId`}
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormInput
                      label='Config ID'
                      name={`selections.${index}.configId`}
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormNumberInput
                      decimalScale={0}
                      label='Default Time (minutes)'
                      name={`selections.${index}.defaultTime`}
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormNumberInput
                      decimalScale={4}
                      label='Grace Time (minutes)'
                      name={`selections.${index}.graceTime`}
                      sx={
                        isGreaterOrLess(
                          graceTime,
                          controlledFields[index].graceTime
                        )
                          ? {
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: 'orange',
                                },
                              },
                              '& .MuiInputLabel-outlined': {
                                color: 'orange',
                              },
                            }
                          : undefined
                      }
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormNumberInput
                      decimalScale={4}
                      label='Reminder Fee'
                      name={`selections.${index}.reminderFee`}
                      sx={
                        isGreaterOrLess(
                          reminderFee,
                          controlledFields[index].reminderFee
                        )
                          ? {
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: 'orange',
                                },
                              },
                              '& .MuiInputLabel-outlined': {
                                color: 'orange',
                              },
                            }
                          : undefined
                      }
                    />
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <FormNumberInput
                      decimalScale={4}
                      label='Convenience Fee'
                      name={`selections.${index}.convenienceFee`}
                      sx={
                        isGreaterOrLess(
                          convenienceFee,
                          controlledFields[index].convenienceFee
                        )
                          ? {
                              '& .MuiOutlinedInput-root': {
                                '&.MuiInputBase-root fieldset': {
                                  borderColor: 'orange',
                                },
                              },
                              '& .MuiInputLabel-outlined': {
                                color: 'orange',
                              },
                            }
                          : undefined
                      }
                    />
                  </div>
                  <Tooltip
                    placement='top'
                    title={
                      !vatNumber
                        ? 'Please provide VAT number in order to toggle this field'
                        : ''
                    }
                  >
                    <div
                      style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <VATTitle>Has VAT</VATTitle>
                      <SwitchButton
                        checked={field.hasVat}
                        disabled={!vatNumber}
                        sx={{ m: 1 }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleVATToggleChange(index, event.target.checked)}
                      />
                    </div>
                  </Tooltip>
                  {field.timeZoneId ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <VATTitle>Time Zone</VATTitle>
                      <VATTitle>{field.timeZoneId}</VATTitle>
                    </div>
                  ) : null}
                </FieldContainer>
              );
            })}
          </FormContainer>
        </FormProvider>
      </ModalContentContainer>
      <Button
        form='customer-form'
        sx={{
          margin: '10px 0',
          width: '90%',
          marginLeft: '5%',
        }}
        type='submit'
        variant='contained'
      >
        {editing ? 'Save Changes' : 'Save New Customer'}
      </Button>
    </Box>
  );
}

export default CustomerFormModal;
