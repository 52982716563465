import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface ICoordinate {
  lat: string;
  lng: string;
}
interface IPolygonData {
  subAreaId: string;
  subareaName: string;
  configId: string;
  defaultTime: number;
  reminderFee?: number;
  convenienceFee?: number;
  coordinates: ICoordinate[];
  areaMap?: string;
  hasVat?: boolean;
}

export interface IPolygonFormData {
  id?: string;
  name: string;
  vatNumber: string;
  defaultReminderFee: number;
  defaultConvenienceFee: number;
  merchantId: string;
  defaultGraceTime: number;
  selections: IPolygonData[];
}

const emailPattern = /^[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Z|a-z]{2,7}$/;
const postcodePattern = /^([A-Z]{1,2}[0-9]{1,2}[A-Z]?)\s?([0-9][A-Z]{2})$/;

const validationSchema = yup.object<IPolygonFormData>().shape({
  name: yup.string().required('Customer Name is required'),
  companyEmail: yup
    .string()
    .matches(emailPattern, 'Invalid email format')
    .required('Customer Email is required'),
  vatNumber: yup.string().nullable(),
  defaultReminderFee: yup.number().required('Reminder Fee is required'),
  defaultConvenienceFee: yup.number().required('Convenience Fee is required'),
  merchantId: yup.string().required('Merchant ID is required'),
  defaultGraceTime: yup.number().nullable(),

  companyAddress: yup.string().when('vatNumber', {
    is: (vatNumber: string | null) => Boolean(vatNumber),
    then: () => yup.string().required('Address is required'),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  companyCity: yup.string().when('vatNumber', {
    is: (vatNumber: string | null) => Boolean(vatNumber),
    then: () => yup.string().required('City is required'),
    otherwise: () => yup.string().nullable().notRequired(),
  }),
  companyRegion: yup.string().nullable().notRequired(),
  companyPostCode: yup.string().when('vatNumber', {
    is: (vatNumber: string | null) => Boolean(vatNumber),
    then: () =>
      yup
        .string()
        .required('Post code is required')
        .matches(postcodePattern, 'Invalid post code format'),
    otherwise: () => yup.string().nullable().notRequired(),
  }),

  selections: yup.array().of(
    yup.object().shape({
      subareaName: yup.string().required('Subarea Name is required'),
      subAreaId: yup.string().required('Subarea ID is required'),
      configId: yup.string().required('Config ID is required'),
      defaultTime: yup.number().required('Default Time (minutes) is required'),
      reminderFee: yup.number().nullable(),
      graceTime: yup.number().nullable(),
      convenienceFee: yup.number().nullable(),
    })
  ),
});

export default yupResolver(validationSchema);
