import { IPayment, paymentMethodTypeValues } from '@Types/Payment';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { formatTime } from '@Utils/formatTime';

const DetailsContainer = styled(Box)`
  margin-top: 30px;
  display: flex;
  gap: 60px;
`;

const Details = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0
`;

const DetailsTitle = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const DetailsValue = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const RefundButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: '18px 20px',
  borderRadius: 20,
  color: '#FFF',
  marginTop: '60px',

  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

interface IProps {
  payment: IPayment;
  onRefund: () => void;
}

export function RefundPayment({ payment, onRefund }: IProps) {
  return (
    <Box>
      <Divider />
      <DetailsContainer>
        <Details>
          <DetailsTitle>User ID</DetailsTitle>
          <DetailsValue>{payment.clientId}</DetailsValue>
        </Details>
        <Details>
          <DetailsTitle>Payment method</DetailsTitle>
          <DetailsValue>
            {paymentMethodTypeValues[payment.paymentMethodType]}
          </DetailsValue>
        </Details>
        <Details >
          <DetailsTitle>Amount</DetailsTitle>
          <DetailsValue>{`£${payment.amount.toFixed(2)}`}</DetailsValue>
        </Details>
        <Details>
          <DetailsTitle>Date</DetailsTitle>
          <DetailsValue>{formatTime(payment.createdAt)}</DetailsValue>
        </Details>
      </DetailsContainer>
      <RefundButton fullWidth onClick={onRefund}>
        Refund
      </RefundButton>
    </Box>
  );
}
