import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ICustomer } from '@Types/customerType';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';

export const customerColumns: (GridColDef<ICustomer> & ExtendedGridColDef)[] = [
  {
    field: 'name',
    headerName: 'Customer Name',
    hideable: false,
    flex: 0.3,
    renderCell: ({ value }) => (
      <Tooltip title={value}>
        <span className='table-cell-truncate'>{value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'subareaName',
    headerName: 'Subarea Name',
    hideable: true,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title={params.row.selections[0]?.subareaName}>
        <span className='table-cell-truncate'>
          {params.row.selections[0]?.subareaName}
        </span>
      </Tooltip>
    ),
  },
  {
    field: 'subAreaId',
    headerName: 'Subarea ID',
    hideable: true,
    flex: 0.2,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title={params.row.selections[0]?.subAreaId}>
        <span className='table-cell-truncate'>
          {params.row.selections[0]?.subAreaId}
        </span>
      </Tooltip>
    ),
  },
  {
    field: 'configId',
    headerName: 'Config ID',
    hideable: true,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title={params.row.selections[0]?.configId}>
        <span className='table-cell-truncate'>
          {params.row.selections[0]?.configId}
        </span>
      </Tooltip>
    ),
  },
];
